.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: rotation 1s infinite linear;
  /* animation: move 10s steps(10) infinite alternate; */
}
.mover {
  animation: move 2s steps(10) infinite alternate;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
    
  }
  to {
    transform: rotate(359deg);
   
  }
  0%    { opacity: 0; }
  25%   { opacity: 0.25; }
  50%   { opacity: 0.50; }
  75%   { opacity: 0.25; }
  100%  { opacity: 0; }
}
@keyframes move {
  from { top: 0; left: 0; }
  to   { top: 0; left: 100; }
}

